


export class BrowserAppEvaluationToolInfo {

  public version = '2024.4.15.2';

  constructor() {
  }

}
